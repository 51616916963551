require('./bootstrap');
var origin = $('#origin').val();
var token = '';
var screenID = $('#screen-id').val();
var inactive
var inactiveCounter = 0;
var inactiveForReboot = 0;
const inactiveTime = 70;//70; //Segundos
const rebootQRTimer = 350;//350; //Segundos
const SOCKET_URL = 'https://contactlesspsexperience.com';//'http://contactless.test';
const LOCALHOST =	'http://contactless.test';

if(origin == 'screen'){
	/*--------------
	VIDEO PLAYER 
	--------------*/
	var video = document.getElementById("myVideo");
	var video_links = document.getElementById("playlist");
	var source = video.getElementsByTagName("source");
	var link_list = [];
	var path = '/videos/';
	var currentVid = 0;
	//var allLnks = video_links.;
	//console.log(allLnks.length)
	var lnkNum = video_links.querySelectorAll('li').length

	video_links.querySelectorAll('li').forEach(
		function (obj){
			link_list.push(obj.getAttribute('data-video'));
		}
	)

	video.addEventListener('ended', function () {
		console.log('terminó')
	//link_list = ['call-to-action.mp4']
	//	if(link_list.length == 1 && link_list[0] == 'call-to-action.mp4'){

		//}
		if ((currentVid + 1) >= link_list.length) {
			nextVid = 0;
		} else {
			nextVid = currentVid+1; 
		}
		playVid(nextVid);
	})

	playVid(0);
}

function playVid(index) {
	source[0].src = path + link_list[index];
	currentVid = index;
	video.load();
	video.play();
	if(link_list[index] != 'lcd-reel-automatico.mp4' && link_list.length > 1){
		$('.container.zocalo').show();
		$('#qr-view').hide();
	}else if(link_list.length > 1){
		$('.container.zocalo').hide();
		$('#qr-view').show();
	}else{
		$('.container.zocalo').hide();
		$('#qr-view').hide();
	}
}
function playVidFromSocket(vid) {
	source[0].src = path + vid;
	video.load();
	video.play();
	console.log(path+vid);
	link_list = ['call-to-action.mp4']
	inactiveCounter = 0;
	currentVid = -1;
	$('.container.zocalo').hide();
}
	

Echo.channel('contactless_database_visited-channel')
.listen('Visited',(data) => {
	//console.log("VISITED")
	if(data.info.refresh_token == 'ok'){
		if(origin == 'screen'){
			$('.container.continue_there').fadeOut();
			inactiveCounter = 0;
			inactiveForReboot = 0;
			inactiveControl(true);
	    	stopPairingControl();
		}
		return false;
	}
	//El ScreenID de la solicitud es el de la pantalla y no hay token
	if(data.info.screen == screenID && token == ''){
		//Si recibo un código es porque tengo una solicitud de emparejamiento
		if(data.info.code){
			console.log("OCULTO LOS QR")
			$('.container.zocalo').hide();
			$('#qr-view').hide();
				$('#myVideo source').attr('src',LOCALHOST + '/videos/lcd-emparejamiento.mp4')
				$('#myVideo')[0].load();
			$('.container.waitin_pairing').show();
			document.getElementById('paring-code').innerHTML = data.info.code.code
			ActivePairingTimer('home');
		}
		//Si recibo un token es porque tengo un emparejamiento exitoso
		if(data.info.token){
			//Guardo el token obtenido en la pantalla para recibir datos del canal
			token = data.info.token
			if(document.getElementById('paring-code')){
				stopPairingControl();
				inactiveCounter = 0;
				inactiveForReboot = 0;
				link_list = ['call-to-action.mp4'];
				//inactiveCounter = 0;
				playVid(0);
				currentVid = -1;
				//$('#myVideo source').attr('src',LOCALHOST + '/videos/call-to-action.mp4')
				//$('#myVideo')[0].load();
				document.getElementById('paring-code').innerHTML = "BIENVENIDO";
				$('.container.waitin_pairing').hide();
				inactiveControl(true);
			}
		}
		//el ScreenID de la solicitud es el de la pantalla pero ya existía un token
	}else if(data.info.screen == screenID && token != '' && !data.info.refresh_code){ //Una solicitud de emparejamiento con una en proceso.
		console.log(data.info)
		if(data.info.token && origin == 'screen'){
			token = data.info.token;
			$('.container.continue_there').hide();
			inactiveControl(true);
			stopPairingControl();
			link_list = ['call-to-action.mp4'];
			//inactiveCounter = 0;
			playVid(0);
			currentVid = -1;
			$('.container.new_user').fadeOut();
			inactiveCounter = 0;
			inactiveForReboot = 0;
			
			console.log("TOKEN RECIBIDO: "+ data.info.token) //BORRAR!!!	
		}else if(origin == 'phone'){
			alert("LOST PAIRING")
		}else{
			$('.container.new_user').fadeIn();
			ActivePairingTimer('new');
			document.getElementById('newuser-code').innerHTML = data.info.code.code
		}		
	}else if(data.info.screen == screenID && token != '' && data.info.refresh_code){
		if(origin == 'phone'){
			$('.container_pairing.refresh_pairing').fadeIn();
			console.log("volver a cargar codigo")
		}
		if(origin == 'screen'){
			document.getElementById('continue-code').innerHTML = data.info.refresh_code.code
		}
	}

});
Echo.channel('contactless_database_interaction-channel')
.listen('Interaction',(data) => {
	console.log("INTERACTION")
	//Si token existe verifico la señal obtenida por el canal
	if(token!=''){
		//Si existe información con mi token, la levanto.
		if(data.info[token]){
			//Si contiene un objeto "Video" muestro el contenido
			if(data.info[token].video){
				if(origin == 'screen'){
					console.log("VER VIDEO "+data.info[token].video)
					playVidFromSocket('productos/'+data.info[token].video);
				}
			}
		}else{
			//Otro contenido
			console.log(data.info[token])
		}
	}else{
		console.log("token emitido:")
		console.log(token)
	}
});

//Inactive function
function inactiveControl(status){
	if(status == true){
		inactive = setInterval(function(){
			inactiveCounter++;
			inactiveForReboot++;
			if(inactiveForReboot >= rebootQRTimer){
				checkUserPairing()
				//Termino con el proceso de medición de actividad porque lo continua el progressbar del Pairing.
				clearInterval(inactive);
				console.log("tiempo cumplido de inacción total")
				inactiveForReboot = 0;
				inactiveCounter = 0;
			}
			if(inactiveCounter >= inactiveTime){
				checkUserPairing()
				//Termino con el proceso de medición de actividad porque lo continua el progressbar del Pairing.
				clearInterval(inactive);
				console.log("tiempo cumplido de inacción")
				inactiveCounter = 0;
				inactiveForReboot = 0;
			}
			console.log(inactiveCounter)
		},1000);
	}else{
		clearInterval(inactive);
	}
}

function checkUserPairing(){
	if($('.container.continue_there').length){
		//document.getElementById('continue-code').innerHTML = data.info.code.code
		console.log("tiro cartel")
		axios.get(SOCKET_URL + '/api/refresh/'+screenID).then(function (response) {

		})
		.catch(function (error) {
			console.log("error");
		})
		$('.container.continue_there').fadeIn();
		ActivePairingTimer('refresh');
	}
}

//Phone Page
/*$('#phone').on('click','.video-btn',function(){
	let videoCode = $(this).attr('rel');
	$.get('/interaction/'+screenID+'/?video='+videoCode).then(function(response){
		$(this).html("Reproduciendo...")
	}).fail(function(err){
		console.log("No funcionó")
	})
	//console.log(videoCode)
})*/

$('#pairingCode').pincodeInput({hidedigits:false,inputs:6,inputtype:'number',complete:function(value, e, errorElement){

	//AXIOS
	if(value.length == 6){
		axios.post('/pairing/'+screenID, {
		  code: value
		})
		.then(function (response) {
			$(window).scrollTop(0);
	        $(".container_pairing").fadeOut();
	        $("body").removeClass("overflow-hidden");
		})
		.catch(function (error) {
			$('.pincode-input-text').val('');
			$('.pincode-input-text')[0].focus();
			$(errorElement).html("Código incorrecto!");
	        $(errorElement).show();
		});
	}
}});

$('#refreshCode').pincodeInput({hidedigits:false,inputs:6,inputtype:'number',complete:function(value, e, errorElement){

	//AXIOS
	if(value.length == 6){
		axios.post('/api/refresh/'+screenID, {
		  code: value,
		  token: token
		})
		.then(function (response) {
	        $(".container_pairing.refresh_pairing").fadeOut();
	        $("body").removeClass("overflow-hidden");
		})
		.catch(function (error) {
			$('.pincode-input-text').val('');
			$('.pincode-input-text')[0].focus();
			$(errorElement).html("Código incorrecto!");
	        $(errorElement).show();
		});
		$('#refreshCode').pincodeInput().data('plugin_pincodeInput').clear();
	}
}});

$('.progression-studios-video-index-container').click(function(){
		let videoCode = $(this).data()
		videoCode = videoCode.video
		$('.progression-studios-video-index-container .progression-studios-video-feaured-image .playing-content').hide();
       	$(this).find(".progression-studios-video-feaured-image .playing-content").show();
       	$.get('/interaction/'+screenID+'/?video='+videoCode+'&token='+token).then(function(response){
       		console.log("mando contenido");
		}).fail(function(err){
			console.log("No funcionó")
		})
       //$('#mobile-bars-icon-pro').click();
    });
$('.carrouselbtn').click(function(){
	let videoCode = $(this).data()
		videoCode = videoCode.video
       	$.get('/interaction/'+screenID+'/?video='+videoCode+'&token='+token).then(function(response){
       		console.log("mando contenido");
		}).fail(function(err){
			console.log("No funcionó")
		})
})

